
import PDFOnlineTypeBlockWrap from '@/components/pdfs/partials/PDFOnlineTypeBlockWrap.vue'
import PDFRegion from '@/components/pdfs/partials/PDFRegion.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFOnlineTypeBlockWorkDescription',
  props: {
    oType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
    titles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    encodedTitles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    disable: {
      type: Object as PropType<{ continued: boolean; toBeContinued: boolean }>,
      required: true,
    },
  },
  data() {
    return {
      workDescriptions: this.oType.getWorkdescriptionTexts(),
    }
  },
  components: { PDFOnlineTypeBlockWrap, PDFRegion },
})
