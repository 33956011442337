import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "PDFRegion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      class: _normalizeClass(["PDFRegionTitle", { 'HeaderSpacing': !_ctx.clearSpacing }])
    }, _toDisplayString(_ctx.heading), 3),
    _renderSlot(_ctx.$slots, "default")
  ]))
}