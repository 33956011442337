import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFPage = _resolveComponent("PDFPage")!
  const _component_PDFPageRender = _resolveComponent("PDFPageRender")!

  return (_openBlock(), _createBlock(_component_PDFPageRender, { allElements: _ctx.elementsList }, {
    default: _withCtx(({ pageFilled, pageStartIndexes, pageElements }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageStartIndexes, (startIndex, index) => {
        return (_openBlock(), _createBlock(_component_PDFPage, {
          key: startIndex,
          class: "PDFComments",
          contentClasses: 'PDFRichText',
          context: _ctx.context,
          elementsList: pageElements,
          startIndex: Number(startIndex) || 0,
          onAllElementsAdded: _ctx.emitCompletion,
          onPageIsFull: pageFilled
        }, _createSlots({ _: 2 }, [
          (index === 0)
            ? {
                name: "sectionTitle",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.section) + ". " + _toDisplayString(_ctx.project?.getCommentsOnProjectTitle()), 1)
                ])
              }
            : undefined
        ]), 1032, ["context", "elementsList", "startIndex", "onAllElementsAdded", "onPageIsFull"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["allElements"]))
}