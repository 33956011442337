
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PDFRegion',
  props: {
    heading: {
      type: String,
      required: true,
    },
    clearSpacing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
