
import PDFPageRender from '@/components/pdfs/partials/PDFPageRender.vue'
import PDFPage from '@/components/pdfs/PDFPage.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PDFComments',
  emits: ['completed'],
  props: {
    section: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      elementsList: [] as Node[],
      rerender: {
        debounce: 500,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        timeout: null as any,
      },
    }
  },
  computed: {
    ...mapState(currentStore, {
      reportNotes: (store) => store.project?.data.reportNotes ?? '',
      project: 'project',
    }),
  },
  methods: {
    emitCompletion() {
      this.$emit('completed')
    },
    compileElementsList() {
      const tmpDiv = document.createElement('div')
      tmpDiv.innerHTML = this.reportNotes

      const nodes = [...tmpDiv.childNodes].reduce((prev, node) => {
        if (node.nodeName !== 'DIV') {
          prev.push(node)
          return prev
        }

        prev = prev.concat([...node.childNodes] as Node[])

        return prev
      }, [] as Node[])

      this.elementsList = nodes.map((node) => {
        if (node.nodeName === '#text') {
          const span = document.createElement('span')

          span.innerText = node.nodeValue as string

          return span
        }

        return node
      })
    },
  },
  components: { PDFPage, PDFPageRender },
  created() {
    this.compileElementsList()
  },
})
