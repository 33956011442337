
import current from '@/store/modules/current/current'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFOnlineTypeBlockWrap',
  props: {
    disableContinued: {
      type: Boolean,
      default: false,
    },
    disableToBeContinued: {
      type: Boolean,
      default: false,
    },
    titles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    encodedTitles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
  },
  data() {
    return {
      mutatedCategoryTitle: this.titles.category.split(' ').slice(1).join(' '),
    }
  },
  computed: {
    ...mapState(current, ['project']),
    isDamageRelatedScreeningProject() {
      return !!(this.project?.data.projectType === 'damage-related-screening')
    },
  },
})
