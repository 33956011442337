
import PDFOnlineType from '@/components/pdfs/partials/PDFOnlineType.vue'
import PDFPageRender from '@/components/pdfs/partials/PDFPageRender.vue'
import PDFPage from '@/components/pdfs/PDFPage.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { ICategoryGroup } from '@/__types__/categoryGroup'
import { currentStore, pdfStore, resourcesStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import Building from '@/globals/javascript/models/proUnit/Building'

type ITOCItem = {
  id: string
  title: string
  pageNumber: string
  typesCount: number
}

interface ICatTOCLink {
  title: string
  idRef: string
}

export interface ICatTOCItem {
  hashID: string
  sectionID: string
  idRef: string
  title: string
  units: string
  outerOTypeLinks: ICatTOCLink[]
  innerOTypeLinks: ICatTOCLink[]
  oType: OnlineType
}

export default defineComponent({
  name: 'PDFCategories',
  emits: ['completed'],
  props: {
    section: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    const sectionTitle = `${this.section}. ${this.mixWB(
      'ASSESSMENT_AND_RECOMMENDATION'
    )}`

    const buildingID = this.context.split('-')[1] ?? ''
    const isBuildingContext = !!buildingID

    return {
      isBuildingContext,
      buildingID,
      sectionTitle,
      tableOfContents: [] as ITOCItem[],
    }
  },
  computed: {
    ...mapState(resourcesStore, ['categoriesAsArray', 'sampleTypesAsArray']),
    ...mapState(currentStore, {
      projectUnits: ({ units }) => units.all,
    }),
    ...mapState(pdfStore, ['pageNumbersAdded']),
    scopedCategoryGroups() {
      if (this.isBuildingContext) {
        return this.getCategoryGroupsByBuildingID(this.buildingID)
      }

      return this.getCategoryGroupsByBuildingID()
    },
    populatedCategories(): ICategoryGroup[] {
      return this.scopedCategoryGroups.withOnlineTypes.filter(
        (c) => c.onlineTypes.directlyRelated.length
      )
    },
    scopedUnitIDs() {
      if (this.isBuildingContext) {
        const contextUnit = this.projectUnits?.find(
          (x) => x.id === this.buildingID
        )

        if (!contextUnit || !(contextUnit instanceof Building)) {
          return []
        }

        return [
          contextUnit.id,
          ...contextUnit.floorIDs,
          ...contextUnit.apartmentIDs,
        ]
      }

      return this.projectUnits.map((x) => x.id)
    },
  },
  watch: {
    pageNumbersAdded: {
      handler(data) {
        if (data[this.context]) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.buildTOC()
            }, 0)
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(currentStore, ['getCategoryGroupsByBuildingID']),
    getTitle(title: string) {
      return `${this.section}.${title}`.replace(/\.\s/gm, ' ')
    },
    getEncodedTitle(title: string) {
      return this.mixEncodeString(this.getTitle(title))
    },
    buildTOC() {
      const reportContainer = document.querySelector(
        `[data-report-reference="${this.context}"]`
      )

      this.tableOfContents = this.scopedCategoryGroups.all.map((x) => {
        const title = this.getTitle(x.title)
        const id = this.mixEncodeString(title)

        let pageNumber = ''
        const categoryStartingPage = reportContainer?.querySelector(
          `[id="${id}"]`
        )

        if (categoryStartingPage) {
          const pageElement = categoryStartingPage.querySelector(
            '.js-pdf-page-number'
          ) as HTMLElement

          pageNumber = pageElement?.innerText ?? ''
        }

        const typesCount = x.onlineTypes.directlyRelated.length

        return {
          id,
          title,
          typesCount,
          pageNumber,
        }
      })

      this.$nextTick(() => {
        setTimeout(() => {
          this.$emit('completed')
        }, 0)
      })
    },
    categoryTOC(oTypes: OnlineType[]): ICatTOCItem[] {
      return oTypes.map((o) => {
        const title = this.getTitle(o.titles.complete)
        const sectionID = title.split(' ')[0]

        // 1. Get interConnections
        const { innerOnlineTypes, outerOnlineTypes } = o

        // Filter method to get only those with relation to current building selection
        const getRelatedInterConnection = (oType: OnlineType) => {
          if (this.isBuildingContext) {
            return oType.unitIDs.some((id) => this.scopedUnitIDs.includes(id))
          }

          return true
        }

        // 2.1 compile reference data
        const innerOTypeLinks = innerOnlineTypes
          .filter(getRelatedInterConnection)
          .map((x) => {
            const title = this.getTitle(x.titles.complete)

            return {
              title: `${this.mixWB('SITS_ON')} ${title}`,
              idRef: `#${this.mixEncodeString(title)}`,
            }
          })

        const outerOTypeLinks = outerOnlineTypes
          .filter(getRelatedInterConnection)
          .map((x) => {
            const title = this.getTitle(x.titles.complete)

            return {
              title: `${this.mixWB('ON_THIS_SITS')} ${title}`,
              idRef: `#${this.mixEncodeString(title)}`,
            }
          })

        return {
          hashID: o.hashID,
          sectionID,
          idRef: `#${this.mixEncodeString(title)}`,
          title,
          units: o.unitsAsString,
          outerOTypeLinks,
          innerOTypeLinks,
          oType: o,
        }
      })
    },
  },
  components: { PDFPageRender, PDFPage, PDFOnlineType },
})
