
import PDFFloorplanBlock from '@/components/pdfs/partials/PDFFloorplanBlock.vue'
import PDFOnlineTypeBlockWrap from '@/components/pdfs/partials/PDFOnlineTypeBlockWrap.vue'
import PDFRegion from '@/components/pdfs/partials/PDFRegion.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFOnlineTypeBlockFloorplans',
  props: {
    oType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    titles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    encodedTitles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    disable: {
      type: Object as PropType<{ continued: boolean; toBeContinued: boolean }>,
      required: true,
    },
  },
  data() {
    return {
      disableToBeContinued: false,
    }
  },
  computed: {
    ...mapState(currentStore, ['samples']),
    relevantSamples() {
      const relevantSamples = this.oType.samples.map((s) => {
        if (s.sampleNumber) {
          return s
        }

        if (s.options.isInternalEquivalent) {
          return s.getReferenceSample()
        }

        return null
      })

      return relevantSamples.filter(Boolean) as ProSample[]
    },
  },
  components: { PDFOnlineTypeBlockWrap, PDFRegion, PDFFloorplanBlock },
})
