import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "PDFRichText" }
const _hoisted_2 = { class: "PDFCategoriesTOCTitle" }
const _hoisted_3 = { class: "PDFCategoriesTOCLine Headline" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "PDFCategoryTitle" }
const _hoisted_7 = { class: "PDFCategoryTOCLine Headline" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFPage = _resolveComponent("PDFPage")!
  const _component_PDFOnlineType = _resolveComponent("PDFOnlineType")!
  const _component_PDFPageRender = _resolveComponent("PDFPageRender")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PDFPage, {
      class: "PDFCategories",
      context: _ctx.context
    }, {
      sectionTitle: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.section) + ". " + _toDisplayString(_ctx.mixWB('ASSESSMENT_AND_RECOMMENDATION')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('PDF_ASSESSMENT_AND_RECOMMENDATION_INTRO_TEXT_1', [_ctx.categoriesAsArray.length.toString()])), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('PDF_ASSESSMENT_AND_RECOMMENDATION_INTRO_TEXT_2')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('PDF_ASSESSMENT_AND_RECOMMENDATION_INTRO_TEXT_3')), 1)
        ]),
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.mixWB('MATERIALS_IN_DIFFERENT_BUILDING_PARTS')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('BUILDING_PART')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('NUMBER_OF_REGISTERED_MATERIALS')), 1)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableOfContents, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "PDFCategoriesTOCLine"
          }, [
            (item.typesCount)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: '#' + item.id
                }, [
                  _createElementVNode("span", null, _toDisplayString(item.title), 1),
                  _createElementVNode("span", null, _toDisplayString(item.typesCount), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('PAGE')) + " " + _toDisplayString(item.pageNumber), 1)
                ], 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(item.title), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('NO_REGISTERED_MATERIALS_FOR_THIS_BUILDING_PART')), 1)
                ]))
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["context"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.populatedCategories, (category) => {
      return (_openBlock(), _createBlock(_component_PDFPageRender, {
        key: category.hashID,
        disableAutoRender: false
      }, {
        default: _withCtx(({ pageFilled, pageStartIndexes, pageElements, grapSlottedElements }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageStartIndexes, (startIndex) => {
            return (_openBlock(), _createBlock(_component_PDFPage, {
              key: startIndex,
              id: _ctx.mixEncodeString(_ctx.getTitle(category.title)),
              class: "PDFCategories",
              context: _ctx.context,
              "data-title": category.title,
              "data-types-count": category.onlineTypes.directlyRelated.length,
              elementsList: pageElements,
              startIndex: Number(startIndex) || 0,
              onPageIsFull: pageFilled,
              onElementsLoaded: grapSlottedElements
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.getTitle(category.title)), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.mixWB('ID')), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.mixWB('UNITS')), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.mixWB('TYPE')), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTOC(category.onlineTypes.directlyRelated), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.sectionID,
                    class: _normalizeClass(["PDFCategoryTOCLine", {IsLast: index === _ctx.categoryTOC(category.onlineTypes.directlyRelated).length - 1}])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(item.sectionID), 1),
                    _createElementVNode("span", null, _toDisplayString(item.units), 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("a", {
                        href: item.idRef
                      }, _toDisplayString(item.title), 9, _hoisted_8),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.innerOTypeLinks, (typeRef) => {
                        return (_openBlock(), _createElementBlock("a", {
                          key: typeRef.idRef,
                          href: typeRef.idRef
                        }, _toDisplayString(typeRef.title), 9, _hoisted_9))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.outerOTypeLinks, (typeRef) => {
                        return (_openBlock(), _createElementBlock("a", {
                          key: typeRef.idRef,
                          href: typeRef.idRef
                        }, _toDisplayString(typeRef.title), 9, _hoisted_10))
                      }), 128))
                    ])
                  ], 2))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTOC(category.onlineTypes.directlyRelated), (item) => {
                  return (_openBlock(), _createBlock(_component_PDFOnlineType, {
                    key: item.oType.hashID,
                    sectionNumber: _ctx.section,
                    context: _ctx.context,
                    titles: {
                section: _ctx.sectionTitle,
                category: _ctx.getTitle(category.title),
                type: _ctx.getTitle(item.oType.titles.complete)
              },
                    encodedTitles: {
                section: _ctx.mixEncodeString(_ctx.sectionTitle),
                category: _ctx.getEncodedTitle(category.title),
                type: _ctx.getEncodedTitle(item.oType.titles.complete)
              },
                    categoryItem: item
                  }, null, 8, ["sectionNumber", "context", "titles", "encodedTitles", "categoryItem"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["id", "context", "data-title", "data-types-count", "elementsList", "startIndex", "onPageIsFull", "onElementsLoaded"]))
          }), 128))
        ]),
        _: 2
      }, 1024))
    }), 128))
  ], 64))
}