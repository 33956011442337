
import Image from '@/components/Image.vue'
import PDFOnlineTypeBlockWrap from '@/components/pdfs/partials/PDFOnlineTypeBlockWrap.vue'
import { ICatTOCItem } from '@/components/pdfs/sections/general/PDFCategories.vue'
import {
  formatEWCNumber,
  isProCoatingType,
} from '@/globals/javascript/_utils/util'
import { uniqBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFOnlineTypeBlockMain',
  props: {
    categoryItem: {
      type: Object as PropType<ICatTOCItem>,
      required: true,
    },
    sectionNumber: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    titles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    encodedTitles: {
      type: Object as PropType<{
        section: string
        category: string
        type: string
      }>,
      required: true,
    },
    disable: {
      type: Object as PropType<{ continued: boolean; toBeContinued: boolean }>,
      required: true,
    },
  },
  data() {
    return {
      oType: this.categoryItem.oType,
    }
  },
  computed: {
    images() {
      // Get all type images (only first for each protype if multitype)
      let typeImages = this.oType.options.isMultiType
        ? this.oType.types.map((pType) => pType.images[0])
        : this.oType.types.map((pType) => pType.images)

      // Remove dublicates
      const uniqueImages = uniqBy(
        typeImages.flat(1).filter(Boolean),
        'base.path'
      )

      return uniqueImages.slice(0, 4)
    },
    details() {
      const { titles, ewcCode } = this.oType

      /**
       * Fraction
       */
      const fraction = {
        title: this.mixWB('FRACTION'),
        text: titles.fraction ?? '-',
        isBold: this.oType.status.className === 'Hazardous',
      }

      /**
       * EWC Code
       */
      const ewcCodeObj = {
        title: this.mixWB('EWC_CODE'),
        text: ewcCode?.id ? formatEWCNumber(ewcCode.id) : '-',
        isBold: this.oType.status.className === 'Hazardous',
      }

      /**
       * Amount
       */

      const amountText = this.oType.amounts.hasMissingAmounts
        ? this.mixWB('NO_AMOUNT_SPECIFIED')
        : this.oType.getTonsTextWithDetails()

      const amount = {
        title: this.mixWB('ESTIMATED_AMOUNT'),
        text: amountText,
        isBold: false,
      }

      /**
       * Notes from ProTypes
       */
      const notesText = this.oType.types.reduceRight(
        (prev, pType, index, arr) => {
          // Ignore uncommented pTypes
          if (!pType.comments) {
            return prev
          }

          // Don't handle ProTypes that are in same OnlineType as it's coating
          const isCoated = !!arr.find(
            (x) => isProCoatingType(x) && x.originID === pType.id
          )

          if (isCoated) {
            return prev
          }

          // Prep data baseline
          const item = {
            title: '',
            text: '',
          }

          // If ProCoatingType - combine title with origin ProType
          if (isProCoatingType(pType)) {
            const proType = arr.find((x) => x.id === pType.originID)

            item.title = proType
              ? `${proType.getTitle()} + ${pType.getTitle()}`
              : pType.getTitle()
            item.text = pType.comments

            prev.push(item)

            return prev
          }

          // Simple ProType addition
          item.title = pType.getTitle()
          item.text = pType.comments

          prev.push(item)

          return prev
        },
        [] as { title: string; text: string }[]
      )

      const note = {
        title: this.mixWB('COMMENTS_2'),
        text: notesText.length ? notesText : '',
        isBold: false,
      }

      return [fraction, ewcCodeObj, amount, note]
    },
  },
  components: { PDFOnlineTypeBlockWrap, Image },
})
