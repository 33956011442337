import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "FloorplansContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFFloorplanBlock = _resolveComponent("PDFFloorplanBlock")!
  const _component_PDFRegion = _resolveComponent("PDFRegion")!
  const _component_PDFOnlineTypeBlockWrap = _resolveComponent("PDFOnlineTypeBlockWrap")!

  return (_openBlock(), _createBlock(_component_PDFOnlineTypeBlockWrap, {
    titles: _ctx.titles,
    encodedTitles: _ctx.encodedTitles,
    disableContinued: _ctx.disable.continued,
    disableToBeContinued: _ctx.disable.toBeContinued
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PDFRegion, {
        class: "PDFOnlineTypeBlockFloorplans",
        heading: _ctx.mixWB('SAMPLE_LOCATION')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relevantSamples, (sample) => {
              return (_openBlock(), _createBlock(_component_PDFFloorplanBlock, {
                key: sample.hashID,
                context: _ctx.context,
                sample: sample,
                showUnitText: true
              }, null, 8, ["context", "sample"]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["heading"])
    ]),
    _: 1
  }, 8, ["titles", "encodedTitles", "disableContinued", "disableToBeContinued"]))
}