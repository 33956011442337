
import PDFPage from '@/components/pdfs/PDFPage.vue'
import { pdfStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

type IPageMeta = {
  title: string
  encodedTitle: string
  pageNumber: string
}

export default defineComponent({
  name: 'PDFTableOfContents',
  props: {
    selector: {
      type: String,
      default: '',
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    this.setTOCState(this.context, !this.selector)

    return {
      sections: [] as IPageMeta[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      timeout: null as any,
      debounce: 500,
      states: {
        processing: true,
      },
    }
  },
  computed: {
    ...mapState(pdfStore, ['processing', 'pageNumbersAdded']),
    initTOCBuild() {
      const isProcessing = Object.values(this.processing[this.context]).some(
        Boolean
      )

      return !isProcessing && this.pageNumbersAdded[this.context]
    },
  },
  watch: {
    initTOCBuild: {
      handler(val) {
        if (!val) {
          return
        }

        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          this.$nextTick(() => {
            this.sections = []
            this.compileTableOfContents()
          })
        }, this.debounce)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(pdfStore, ['setTOCState']),
    compileTableOfContents() {
      const pages = [
        ...document.querySelectorAll(this.selector),
      ] as HTMLElement[]

      const sectionPages = pages.filter(
        (page) => !!page.querySelector('.js-section-headline')
      )

      sectionPages.forEach((page) => {
        const sectionHeadlineElement = page.querySelector(
          '.js-section-headline'
        ) as HTMLElement

        if (!sectionHeadlineElement) {
          return
        }

        // Get current sections title
        const sectionTitle = sectionHeadlineElement.innerText

        // Get current section starts page number
        const pageNumber = page.querySelector('.js-pdf-page-number')?.innerHTML

        // Set encodedTitle on the page containing section title
        const encodedTitle = this.mixEncodeString(sectionTitle)

        page.setAttribute('id', encodedTitle)

        // Populate section info array
        this.sections.push({
          title: sectionTitle ?? '',
          encodedTitle,
          pageNumber: pageNumber ?? '',
        })
      })

      this.$nextTick(() => {
        this.setTOCState(this.context, true)
      })
    },
  },
  components: { PDFPage },
  beforeUnmount() {
    this.setTOCState(this.context, false)
  },
})
