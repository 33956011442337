import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "Headline"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFRegion = _resolveComponent("PDFRegion")!
  const _component_PDFOnlineTypeBlockWrap = _resolveComponent("PDFOnlineTypeBlockWrap")!

  return (_openBlock(), _createBlock(_component_PDFOnlineTypeBlockWrap, {
    titles: _ctx.titles,
    encodedTitles: _ctx.encodedTitles,
    disableContinued: _ctx.disable.continued,
    disableToBeContinued: _ctx.disable.toBeContinued
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PDFRegion, {
        class: "PDFOnlineTypeBlockWorkDescription",
        heading: _ctx.mixWB('WORK_DESCRIPTION')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workDescriptions || [], (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.text
            }, [
              (item.type === 'header')
                ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(item.text), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(item.text), 1))
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["heading"])
    ]),
    _: 1
  }, 8, ["titles", "encodedTitles", "disableContinued", "disableToBeContinued"]))
}